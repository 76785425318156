import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { compose, path, pick } from 'ramda';

import { logoutAction } from '../actions/authActions';
import { logout } from '../services/authService';

import Icon from './icon';

import logo from '../img/logo.svg';
import sprite from '../img/sprite.svg';

const Header = ({ className, toggleSidebar, isSidebarOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email, name, sessionId } = useSelector(path(['auth', 'user']));

  // const { email, name, sessionId } = useSelector((state) => state.auth.user);

  const onLogoutClick = async () => {
    logout(sessionId).then(() => {
      dispatch(logoutAction());
      history.push('/login');
    });
  };

  const onSidebarTriggerClick = () => {
    if (!toggleSidebar || typeof toggleSidebar !== 'function') return;

    toggleSidebar();
  };

  return (
    <header className={`header ${className}`}>
      <div className="header-trigger">
        <button
          className={cn('trigger', { 'is-active': isSidebarOpen })}
          onClick={onSidebarTriggerClick}
        >
          <svg
            className="trigger-icon"
            width="32"
            height="32"
            aria-hidden="true"
          >
            <use
              className="trigger-ellipsis"
              xlinkHref={`${sprite}#ellipsis`}
            />
            <use className="trigger-times" xlinkHref={`${sprite}#times`} />
          </svg>
        </button>
      </div>

      <div className="header-logo">
        <p className="logo">
          OsagoTop.ru
          {/* <img src={logo} alt="OsagoTop.ru" width="127" height="29" /> */}
        </p>
      </div>

      <div className="header-user">
        <p className="header-username">{name}</p>
        <p className="text-royalblue mb-2">{email}</p>
        <button className="header-logout" onClick={onLogoutClick}>
          Выйти
          <Icon
            name="signout"
            className="fill-current ml-2"
            width={14}
            height={14}
          />
        </button>
      </div>
    </header>
  );
};

export default Header;
