import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import cogoToast from 'cogo-toast';

import { passResetService } from '../services/passResetService';

const schema = {
  email: {
    required: 'Укажите ваш email или телефон',
    pattern: {
      // value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      value: /^([0-9\\+-\\s]{6,})|(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      message: 'Неправильный формат',
    },
  },
};

const PassResetForm = () => {
  const { register, handleSubmit, errors, reset } = useForm();

  const onResetFormSubmit = async (data) => {
    const [err, res] = await passResetService(data);
    const { error, message } = res.data;

    !error
      ? cogoToast.success(
          <p>
            Новый пароль отпрален на : <strong>{data.email}</strong> вернитесь НАЗАД
          </p>,
          { position: 'top-right' }
        )
      : cogoToast.error(message, { position: 'top-right' });

    reset({});
  };

  return (
    <form
      className="form w-full max-w-lg"
      onSubmit={handleSubmit(onResetFormSubmit)}
      noValidate
    >
      <header className="text-center mb-8">
        <h1 className="logo text-3xl">OsagoTop.ru</h1>
        <p className="font-medium">Восстановление пароля</p>
      </header>
      <div className="mb-8">
        <label htmlFor="email" className="form-label">
          Электронная почта или мобильный телефон
        </label>
        <input
          id="email"
          className={cn('input', { 'input--danger': errors.email })}
          type="email"
          name="email"
          placeholder="Ваш емейл или телефон"
          ref={register(schema.email)}
        />
        <p className="helper helper--danger mt-1">
          {errors.email && errors.email.message}
        </p>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <button
          className="btn btn--primary w-full sm:w-auto mb-2 sm:mb-0 py-3"
          type="submit"
        >
          Сбросить
        </button>
        <Link
          to="/"
          className="text-sm text-royalblue font-semibold hover:underline"
        >
          НАЗАД
        </Link>
      </div>
    </form>
  );
};

export default PassResetForm;
