import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';

import { agreementService } from '../services/agreementService';

import Layout from '../components/layout';
import AgreementItem from '../components/agreementItem';

const DashboardPage = () => {
  const [agreements, setAgreements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { sessionId } = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsLoading(true);

    agreementService(sessionId).then(handleAgreementServiceResponse);
  }, []);

  const handleAgreementServiceResponse = ([err, res]) => {
    const { agreementList, error, message } = res.data.data;

    if (error) {
      cogoToast.error(message, { position: 'top-right' });
      return;
    }

    setIsLoading(false);
    setAgreements(agreementList);
  };

  const renderAgreementsList = () => {
    if (agreements.length === 0 && !isLoading) {
      return (
        <tr>
          <td colSpan="5">
            Получаем данные от страховой компании, это может занять от 5 до 30
            минут. <br /> Пожалуйста, подождите.
          </td>
        </tr>
      );
    }

    if (agreements.length > 0) {
      return agreements.map((agreement, key) => (
        <AgreementItem key={key} {...agreement} />
      ));
    }

    return (
      <tr>
        <td colSpan="5">Загрузка...</td>
      </tr>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>Личный кабинет | OsagoTop.ru</title>
      </Helmet>
      <div className="box box--bordered">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Дата покупки полиса</th>
              <th scope="col">Дата окончания полиса</th>
              <th scope="col">Стоимость полиса</th>
              <th scope="col">Страховая компания</th>
              <th scope="col" colSpan="2">Действия с договором</th>
            </tr>
          </thead>
          <tbody>
            {renderAgreementsList()}
            <td></td>
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default DashboardPage;
