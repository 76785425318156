import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { trackLK } from '../metriks';

import PassResetForm from '../components/passResetForm';

const PassResetPage = () => {
  useEffect(() => {
    trackLK('reset')
  }, [])

  return (
    <div className="reset p-4">
      <Helmet>
        <title>Восстановление пароля | OsagoTop.ru</title>
      </Helmet>
      <PassResetForm />
    </div>
  )
};
export default PassResetPage;
