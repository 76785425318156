import React from 'react';
import cn from 'classnames';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Icon from './icon';

const Sidebar = ({ className, isOpen }) => {
  // const match = useRouteMatch('/message/:id');
  const { hasNew } = useSelector((state) => state.messages);

  // console.log(match);

  // console.log(['/messages', '/message/:id'].includes(pathname));

  return (
    <aside className={cn(`sidebar ${className}`, { 'is-open': isOpen })}>
      <nav className="sidebar-nav">
        <ul className="menu">
          <li className="menu-item">
            <NavLink
              exact
              to="/"
              className="menu-link "
              activeClassName="is-active"
            >
              <Icon
                name="shopping"
                className="fill-current mr-2 text-royalblue"
                width={18}
                height={18}
              />
              Мои полисы
            </NavLink>
          </li>

          <li className="menu-item">
            <NavLink
              to="/messages"
              className="menu-link"
              activeClassName="is-active"
            >
              <div className={cn('menu-icon', { 'has-dot': hasNew })}>
                <Icon
                  name="envelope"
                  className="fill-current mr-2 text-royalblue"
                  width={18}
                  height={18}
                />
              </div>
              Сообщения
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
