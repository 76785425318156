import React from 'react';
import { useSelector } from 'react-redux';
import download from 'downloadjs';
import cogoToast from 'cogo-toast';
import dayjs from 'dayjs';

import { companyNamesRu } from '../helpers/enums';
import { printDocumentService } from '../services/printDocumentService';

import Icon from './icon';
import { trackLK } from '../metriks';

const AgreementItem = ({ id, started, ended, price, company, prolongUrl }) => {
  const { sessionId } = useSelector((state) => state.auth.user);

  const printDocument = ([err, res]) => {
    const { error, message, data } = res.data;

    if (error) {
      cogoToast.error(message);
      return;
    }

    data.documents.forEach((document) =>
      download(
        `data:application/pdf;base64,${document.content}`,
        document.name,
        document.type
      )
    );
  };

  const onDownloadClick = () => {
    trackLK('download');
    printDocumentService({
      sessionId,
      company,
      agreementId: id,
    }).then(printDocument);
  };

  return (
    <tr>
      <td data-label="Дата покупки полиса">{dayjs(started).format('DD-MM-YYYY')}</td>
      <td data-label="Дата окончания полиса">{dayjs(ended).format('DD-MM-YYYY')}</td>
      <td data-label="Стоимость полиса">{price} руб.</td>
      <td data-label="Страховая компания">{companyNamesRu[company]}</td>
      <td data-label="Действия">
        <button
          onClick={onDownloadClick}
          className=" inline-flex items-center text-royalblue whitespace-no-wrap hover:underline"
        >
          Скачать полис
          <Icon
            name="paperclip"
            className="fill-current ml-2"
            width={14}
            height={14}
          />
        </button>
      </td>
      <td data-label="Действия">
        <a onClick={() => trackLK('prolong')} target="_blank" rel="noopener noreferrer" href={prolongUrl || "https://osagoTop.ru/"} className="inline-flex items-center text-royalblue whitespace-no-wrap hover:underline">Продлить полис</a>
      </td>
    </tr>
  );
};

export default AgreementItem;
